.leaflet-container {
  width: 100%;
  height: 90vh;
}

.leaflet-popup-content-wrapper {
  background: black;
  color: white;
}

.checkbox {
  margin-left: 100px; /* Adjust the value as needed */
}

.suggestions {
  /* Existing styles */
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  width: fit-content;
  z-index: 9999; /* Set a high z-index value to ensure it's displayed above other elements */
  /* Additional styles */
  /* Add padding, max-height, and overflow-y to limit the height and provide scroll if needed */
  padding: 8px 0;
  max-height: 200px; /* Adjust this value according to your UI */
  overflow-y: auto; /* Enable vertical scrolling if the suggestions exceed the height */
}

.suggestion-item {
  color: black;
  margin-left: 10px;
  margin-right: 10px;
  
}

.suggestion-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  
}

.searchError {
  position: absolute;
  margin-top: 8px;
  width: fit-content;
  z-index: 9999;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
}


#checkboxDog {
  margin-left: 95px; /* Adjust the value as needed */
}


/* Example CSS */
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure it's above other elements */
}
